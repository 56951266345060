<template>
  <div class="modal">
    <div class="modal-title main-block-title">{{ modalData.title }}</div>
    <div class="modal-close" @click="$emit('close')">
      <icon-close color="#19191C" />
    </div>
    <div class="modal-content">
      {{ modalData.description }}
    </div>
  </div>
</template>

<script>
import IconClose from "@/assets/icons/IconClose";
export default {
  name: 'AddressFromModal',
  components: {
    IconClose
  },
  props: {
    prompt: {
      type: Object,
      required: true
    }
  },
  computed: {
    modalData() {
      return {
        title: this.prompt.title ? this.prompt.title : '',
        description: this.prompt.description ? this.prompt.description : ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.modal {
  position: absolute;
  left: 0;
  top: 0;
  padding: 40px;
  background: rgba(255, 255, 255, .97);
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 10;


  &-title {
    margin-bottom: 20px;
  }

  &-content {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 28px;
  }

  &-close {
    position: absolute;
    right: 40px;
    top: 40px;
    cursor: pointer;
  }
}
</style>
