<template>
  <div class="main-block">
    <div class="main-block-title">{{ title }}   <icon-info color="#19191C" size="15" @click.native="openModal"/></div>
    <div class="main-block-content">

      <form @submit.prevent="submit">
        <div class="grid">
          <form-group label="Country" :value="form.country_id" :errors="errors.country_id">
            <v-select :options="countries" data-test="country" @input="validate(['country_id'])" :reduce="option => option.id" v-model="form.country_id" label="label" class="form-control"></v-select>
          </form-group>

          <form-group label="City" :value="form.city" :errors="errors.city">
            <input v-model="form.city" data-test="city" @change="validate(['city'])" type="text" class="form-control"/>
          </form-group>

          <form-group label="Street" :value="form.street" :errors="errors.street">
            <input v-model="form.street" data-test="street" @change="validate(['street'])" type="text" class="form-control"/>
          </form-group>

          <form-group label="House" :value="form.house" :errors="errors.house">
            <input v-model="form.house" data-test="house" @change="validate(['house'])" type="text" class="form-control"/>
          </form-group>

          <form-group label="Flat" :value="form.apartment" :errors="errors.apartment">
            <input v-model="form.apartment" data-test="apartment" @change="validate(['apartment'])" type="text" class="form-control"/>
          </form-group>

          <form-group label="Postal Code" :value="form.postal_code" :errors="errors.postal_code">
            <input v-model="form.postal_code" data-test="postal_code" @change="validate(['postal_code'])" type="text" class="form-control"/>
          </form-group>
        </div>

        <div class="form-buttons text-right">
          <button type="submit" class="btn-next" data-test="submit">
            <span>Save and proceed</span>
            <icon-next color="#19191C" size="15"/>
          </button>
        </div>
      </form>

    </div>

    <transition name="tab-change-fade">
      <div class="main-block-overload" v-show="showOverlay" data-test="same-address">
        <div>
          <p>Same as legal address?</p>
          <button class="btn btn-yellow btn-bordered" @click.prevent="loadData">Yes</button>
          <button class="btn btn-default" @click="showOverlay = false">No</button>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <address-form-modal :prompt="modalContent" v-show="modal" v-on:close="closeModal"/>
    </transition>

  </div>
</template>

<script>
import AddressFormModal from "@/views/modals/AddressFormModal";
import FormGroup from "@/components/FormGroup";
import IconInfo from '@/assets/icons/IconInfo';
import IconNext from "@/assets/icons/IconNext";


import { mapState } from 'vuex'

const LEGAL = 'LEGAL'
const OPERATING = 'OPERATING'

export default {
  name: 'AddressForm',
  components: {
    AddressFormModal,
    FormGroup,
    IconInfo,
    IconNext
  },
  props: {
    type: {
      type: String,
      validator (value) {
        return [LEGAL, OPERATING].indexOf(value) !== -1
      }
    }
  },
  data () {
    return {
      form: {
        country_id: undefined,
        city: '',
        street: '',
        house: '',
        apartment: '',
        postal_code: ''
      },

      modalPrompts: {
        'LEGAL': {
          title: 'Legal Address',
          description: 'Legal address is an official correspondence address of registered office that every limited company is legally required to provide during the incorporation process and maintain throughout its existence.'
        },
        'OPERATING': {
          title: 'Operating Address',
          description: 'Operating address is where you actually run your business from.'
        }
      },

      errors: {},
      showOverlay: false,
      modal: false
    }
  },
  computed: {
    ...mapState({
      countries: state => state.init.countries,
      registrationData: state => state.registration.data,
      registrationHash: state => state.registration.hash,
    }),
    title () {
      return this.type === LEGAL
          ? 'Legal address'
          : 'Operating address'
    },
    formData () {
      return {
        country_id: this.form.country_id || '',
        city: this.form.city,
        street: this.form.street,
        house: this.form.house,
        apartment: this.form.apartment,
        postal_code: this.form.postal_code,
        type: this.type
      }
    },
    modalContent () {
      return this.modalPrompts[this.type]
    }
  },
  mounted() {
    this.loadAddressByType(this.type)

    if (this.type === OPERATING && !this.checkIsCompleted(OPERATING) && this.checkIsCompleted(LEGAL)) {
      this.showOverlay = true
    }
  },
  methods: {
    openModal () {
      this.modal = true
    },
    closeModal () {
      this.modal = false
    },
    checkIsCompleted (type) {
      return this.registrationData.address.isCompleted[type]
    },

    /**
     * Load business data
     */
    loadData () {
      this.loadAddressByType(LEGAL)
      this.showOverlay = false
    },

    /**
     * Load address by type
     * @param type
     */
    loadAddressByType (type) {
      const address = this.registrationData.address.items.find(item => {
        return item.attributes.type === type
      })

      if (address) {
        const data = address.attributes

        this.form.city = data.city
        this.form.street = data.street
        this.form.house = data.house
        this.form.apartment = data.apartment
        this.form.postal_code = data.postal_code
        this.form.country_id = data.country_id
      }
    },

    /**
     * Form submit action
     */
    submit () {
      let promise = undefined

      if (this.checkIsCompleted(this.type)) {
        promise = this.$sdk.registration.address.update(this.registrationHash, this.type, this.formData, 0)
      } else {
        promise = this.$sdk.registration.address.create(this.registrationHash, this.formData, 0)
      }

      promise.then(response => {
        if (Array.isArray(response.data) && response.data.length === 0) {
          this.$store.dispatch('updateRegistration').then(() => {
            this.$parent.$emit('next-step')
          })
        } else {
          this.errors = response.data
        }
      })
    },

    /**
     * Fields validating
     * @param attributes
     */
    validate (attributes = []) {
      let promise = undefined

      if (this.checkIsCompleted(this.type)) {
        promise = this.$sdk.registration.address.update(this.registrationHash, this.type, this.formData, 1)
      } else {
        promise = this.$sdk.registration.address.create(this.registrationHash, this.formData, 1)
      }

      promise.then(response => {
        for (const attr of attributes) {
          if (Object.prototype.hasOwnProperty.call(response.data, attr)) {
            this.$set(this.errors, attr, response.data[attr])
          } else if (this.errors[attr] !== undefined) {
            this.$delete(this.errors, attr)
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .main-block-title {
    display: flex;
    align-items: center;

    div {
      margin-left: 8px;
    }
  }
</style>
